<script lang="ts" setup>
import type { ProductsSectionRecord } from "~/graphql/datocms/generated";
import { SortableFields, SortOrder } from "~/graphql/propeller/generated";

const props = defineProps<{ data: ProductsSectionRecord; currentPageId: string }>();

const nuxtApp = useNuxtApp();
const { locale } = useI18n();

const entityList = Array.from(props.data.products as Array<{ id: string; code: string; name: string }>);
const entityIdList = entityList.map((e: { id: string; code: string; name: string }) => e.id);

const { data: results, status } = await useFetch("/api/ecom/products/search", {
  query: {
    searchType: "catalog",
    clusterIds: entityIdList,
    sortField: SortableFields.categoryOrder,
    sortOrder: SortOrder.DESC,
    locale: locale,
  },
  getCachedData: key => nuxtApp.payload.data[key] ?? nuxtApp.static.data[key],
});
</script>

<template>
  <div v-if="status !== 'pending' && results">
    <h2 v-if="data.optTitle" class="mb-4">
      {{ data.optTitle }}
    </h2>

    <SwiperProducts
      :products="results.products"
      :slides-per-view="4"
      navigation-position="initial"
    />
  </div>
</template>

<style scoped>

</style>
